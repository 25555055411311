
             
.fude-dialog.auto-width.xs {
    .app-lid-dialog {
        min-width: 220px;
    }
}
.fude-dialog.auto-width.sm {
    .app-lid-dialog {

    }
}
.fude-dialog.auto-width.md {
    .app-lid-dialog {
        min-width: 900px;
    }
}
.fude-dialog.auto-width.lg {
    .app-lid-dialog {
        max-width: 1200px;
    }
}
.fude-dialog.auto-width.xl {
    .app-lid-dialog {
        max-width: 1200px;
    }
}

.app-lid-dialog {
    margin: 0 !important;
    .v-text-field__details {
        display: none;
    }
    .app-lid-dialog__new-comment {
        .v-input__append-inner {
            align-self: flex-end;
            margin-top: 0;
            margin-bottom: 6px;
            z-index: 10;
        }
        .v-input__slot {
            min-height: 0!important;
        }
        textarea {
            margin-top: 0!important;
        }
    }
    .app-lid-dialog__comment {
        padding: 10px;
        margin-top: 10px;
        background-color: #424242;
        .app-lid-dialog__comment__ts {
            font-size: 12px;
        }
        .app-lid-dialog__comment__user {
            font-size: 12px;
        }
        pre {
            word-break: break-word;
            white-space: pre-wrap;
        }
    }
}
