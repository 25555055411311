
             @import "variables";

.xsmall.v-btn {
  width: 28px;
  height: 28px;
}
.btn--bottom.btn--absolute.xsmall {
  bottom: -17px;
}

.dense.v-chip {
  margin: 0;
}
.dense.v-chip .v-chip__content {
  height: 24px;
}

.app-card {
  padding: @component-padding;
}

.v-toolbar__title {
  a {
    color: #FFC107;
    text-decoration: none;
  }
}

.v-input__slot {
  margin: 0;
}

/*.v-dialog__content {
  overflow-y: auto;
  align-items: baseline;
}
.fude-dialog {
  height: auto !important;
  max-height: none !important;
}*/

.fude-dialog.v-dialog--scrollable {
  flex: inherit !important;
}



