
             
.app-content {
    &&.app-content--center {
        .app-content__container {
            display: flex;
            margin: auto;
            height: 100%;
        }
    }
}
